package empiresmp.components.navbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.graphics.Image
import empiresmp.components.ThemeButton
import ru.astrainteractive.kobwebx.core.resource.Resources
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.presentation.ThemeKrate

@Composable
fun EmpireSmpDesktopNavBar() {
    val theme = LocalSitePalette.current
    NavBar {
        Image("favicon.ico", modifier = Modifier.padding(theme.dimens.L).onClick { ThemeKrate.next() })
        Row(Modifier.fillMaxHeight(), verticalAlignment = Alignment.CenterVertically) {
            AdaptiveLink(
                path = "/",
                text = "Главная",
                iconPath = Resources.home.path,
            )

            AdaptiveLink(
                path = "/wiki",
                text = "Вики",
                iconPath = Resources.help.path,
            )
            AdaptiveLink(
                "/votes",
                "ГОЛОСОВАНИЕ",
                iconPath = Resources.bolt.path,
            )
        }
        ThemeButton()
    }
}
