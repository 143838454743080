package empiresmp.components.navbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.css.functions.saturate
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backdropFilter
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun NavBar(
    modifier: Modifier = Modifier,
    actions: @Composable RowScope.() -> Unit
) {
    val theme = LocalSitePalette.current
    Row(
        modifier
            .fillMaxWidth()
            .backgroundColor(theme.colors.colorPrimary.toRgb().copy(alpha = 164))
            .position(Position.Sticky)
            .top(0.percent)
            .backdropFilter(saturate(180.percent), blur(5.px))
            .boxShadow(spreadRadius = 1.px, color = theme.colors.colorPrimary)
            .fillMaxWidth()
            .zIndex(100),
        horizontalArrangement = Arrangement.SpaceBetween,
        content = actions
    )
}
