package empiresmp.components

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSNumeric
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun FilledText(
    text: String,
    fillColor: CSSColorValue,
    textColor: CSSColorValue,
    borderRadius: CSSNumeric = LocalSitePalette.current.dimens.L,
    modifier: Modifier = Modifier,
    onClick: (SyntheticMouseEvent) -> Unit = {}
) {
    val theme = LocalSitePalette.current

    SpanText(
        text = text,
        modifier = theme.typography.h3.toModifier()
            .then(modifier)
            .color(theme.colors.colorOnPrimary)
            .color(textColor)
            .backgroundColor(fillColor)
            .borderRadius(borderRadius)
            .textAlign(TextAlign.Center)
            .padding(topBottom = theme.dimens.S, leftRight = theme.dimens.L)
            .fontWeight(FontWeight.Bold)
            .onClick(onClick)

    )
}
