package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.FlexWrap
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun FlowRow(
    content: @Composable RowScope.() -> Unit,
) {
    val theme = LocalSitePalette.current
    Box(Modifier.width(theme.dimens.S))
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .flexWrap(FlexWrap.Wrap)
                .gap(theme.dimens.M),
            content = content
        )
    }
}
