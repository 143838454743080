package ru.astrainteractive.kobwebx.core.theme.palette

import org.jetbrains.compose.web.css.CSSNumeric

@Suppress("ConstructorParameterNaming")
class Dimens(
    val XS: CSSNumeric,
    val S: CSSNumeric,
    val M: CSSNumeric,
    val L: CSSNumeric,
    val XL: CSSNumeric,
    val XXL: CSSNumeric
)
