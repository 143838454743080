package ru.astrainteractive.kobwebx.core.theme.presentation

import ru.astrainteractive.kobwebx.core.krate.DefaultEnumKrate
import ru.astrainteractive.kobwebx.core.krate.EnumKrate
import ru.astrainteractive.kobwebx.core.theme.model.ThemeType

object ThemeKrate : EnumKrate<ThemeType> by DefaultEnumKrate(
    key = "COLOR_MODE_KEY",
    entries = { ThemeType.entries }
)
