package empiresmp.feature.votes.presentation

import kotlinx.browser.localStorage
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlin.js.Date
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds

class VotesComponent {

    private fun buildUrls(): List<VoteUrl> {
        return buildList {
            VoteUrl.MinecraftRating(207380, "EMPIRE SMP 1.20.4").run(::add)
            VoteUrl.MinecraftRating(179935, "Shchizo SMP 1.20.1").run(::add)
            VoteUrl.MinecraftRating(178365, "Empire SMP 1.16.5").run(::add)
            VoteUrl.MinecraftRating(239218, "Cross Survival 1.20.4").run(::add)
            VoteUrl.MinecraftRating(207379, "Meta Play 1.20.4").run(::add)
            VoteUrl.MinecraftRating(207377, "Ultra SMP 1.20.4").run(::add)
            VoteUrl.MinecraftRating(178893, "Durka SMP 1.20.1").run(::add)
            VoteUrl.MinecraftRating(178007, "City Survival 1.16.5").run(::add)
            VoteUrl.MinecraftRating(109844, "Durka Survival 1.16.5").run(::add)
            VoteUrl.MinecraftRating(99284, "Cross Survival 1.20.1").run(::add)
        }
    }

    private val _urls = MutableStateFlow(buildUrls())
    val urls: StateFlow<List<VoteUrl>> = _urls.asStateFlow()

    fun onClicked(voteUrl: VoteUrl) {
        when (voteUrl) {
            is VoteUrl.MinecraftRating -> {
                localStorage.setItem(voteUrl.id.toString(), Date.now().toLong().toString())
            }
        }
        _urls.value = buildUrls()
    }

    sealed interface VoteUrl {
        val name: String
        val url: String
        val isClicked: Boolean

        class MinecraftRating(
            val id: Long,
            override val name: String,
        ) : VoteUrl {
            override val url: String = "https://minecraftrating.ru/vote/$id/"

            override val isClicked: Boolean = let {
                val now = Date.now().milliseconds
                val date = localStorage.getItem(id.toString())?.toLongOrNull()?.milliseconds ?: 0.milliseconds
                now - date < 16.hours
            }
        }
    }
}
