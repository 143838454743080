package empiresmp.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import empiresmp.components.footer.EmpireFooter
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun PageLayout(content: @Composable () -> Unit) {
    val theme = LocalSitePalette.current
    Column(
        Modifier
            .fillMaxSize()
            .background(theme.colors.colorPrimaryVariant)
            .backgroundColor(theme.colors.colorPrimaryVariant)
    ) {
        content.invoke()
        EmpireFooter()
    }
}
