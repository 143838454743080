package empiresmp.components.hrefcard

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.vars.animation.TransitionDurationVars
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.toSitePalette
import ru.astrainteractive.kobwebx.core.theme.composable.toThemeType

val HrefCardStyle = ComponentStyle("href-card-style") {
    val theme = colorMode.toThemeType().toSitePalette()
    base {
        Modifier
            .borderRadius(theme.dimens.S)
            .border(2.px, LineStyle.Solid, theme.colors.colorHint)
            .padding(theme.dimens.M)
            .cursor(Cursor.Pointer)
            .transition(CSSTransition("ease-in-out", duration = TransitionDurationVars.Normal.value()))
            .margin(theme.dimens.S)
            .backgroundColor(theme.colors.colorPrimaryVariant.toRgb().copy(alpha = 200))
    }
    hover {
        Modifier.backgroundColor(theme.colors.colorPrimary)
    }
}
