package empiresmp.feature.wiki.ui

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import empiresmp.components.PlatformExt.isMobilePlatform
import empiresmp.components.layouts.PageLayout
import empiresmp.components.navbar.EmpireSmpDesktopNavBar
import empiresmp.feature.wiki.ui.component.MainWikiLayout
import org.jetbrains.compose.web.css.percent
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Suppress("LongMethod")
@Composable
fun WikiPageContent(fragment: String) {
    val theme = LocalSitePalette.current

    PageLayout {
        EmpireSmpDesktopNavBar()
        Row(
            modifier = Modifier.fillMaxWidth().padding(topBottom = theme.dimens.L),
            horizontalArrangement = Arrangement.Center
        ) {
            Column(
                Modifier
                    .padding(left = theme.dimens.L, right = theme.dimens.L)
                    .thenIf(isMobilePlatform, Modifier.width(95.percent))
                    .thenIf(!isMobilePlatform, Modifier.width(80.percent)),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                MainWikiLayout()
            }
        }
    }
}
