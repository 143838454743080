package empiresmp.feature.votes.ui

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.Anchor
import empiresmp.components.FilledText
import empiresmp.components.FlowRow
import empiresmp.components.Spacer
import empiresmp.components.layouts.PageLayout
import empiresmp.components.navbar.EmpireSmpDesktopNavBar
import empiresmp.feature.main.presentation.model.MainPagerModel
import empiresmp.feature.main.ui.component.MainPagerModelContent
import empiresmp.feature.votes.presentation.VotesComponent
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun VotesPageContent() {
    val theme = LocalSitePalette.current
    val votesComponent = VotesComponent()
    var lastClickedIndex by remember { mutableStateOf<Int?>(null) }
    val urls by votesComponent.urls.collectAsState()
    PageLayout {
        EmpireSmpDesktopNavBar()
        Column(modifier = Modifier.fillMaxSize()) {
            MainPagerModelContent(MainPagerModel.Monitoring)
            Spacer()
            FlowRow {
                urls.forEachIndexed { i, voteUrl ->
                    Anchor(
                        href = voteUrl.url,
                        attrs = Modifier.textDecorationLine(TextDecorationLine.None).padding(theme.dimens.S).toAttrs(),
                        null,
                        null,
                        true
                    ) {
                        FilledText(
                            text = voteUrl.name,
                            fillColor = theme.colors.colorSecondary,
                            textColor = theme.colors.colorOnSecondary,
                            modifier = Modifier.thenIf(
                                voteUrl.isClicked,
                                Modifier.boxShadow(0.px, 0.px, 10.px, 15.px, theme.colors.colorSecondaryVariant)
                            ),
                            onClick = {
                                lastClickedIndex = i
                                votesComponent.onClicked(voteUrl)
                            }
                        )
                    }
                }
            }
        }
    }
}
