package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.setVariable
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.forms.ButtonVars
import com.varabyte.kobweb.silk.components.icons.MoonIcon
import com.varabyte.kobweb.silk.components.icons.SunIcon
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.presentation.ThemeKrate

@Composable
fun ThemeButton() {
    val theme = LocalSitePalette.current
    Box(
        modifier = Modifier.fillMaxHeight().padding(leftRight = theme.dimens.L),
        contentAlignment = Alignment.Center
    ) {
        Button(
            onClick = { ThemeKrate.next() },
            Modifier
                .padding(theme.dimens.S)
                .borderRadius(50.percent)
                .setVariable(
                    ButtonVars.FontSize,
                    1.em
                )
                .backgroundColor(theme.colors.colorSecondary)
                .background(theme.colors.colorSecondary)
                .color(theme.colors.colorOnSecondary),
        ) {
            if (theme.isDark) SunIcon(Modifier.fillMaxSize()) else MoonIcon(Modifier.fillMaxSize())
        }
    }
}
