package empiresmp.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import empiresmp.feature.votes.ui.VotesPageContent

@Page("/votes")
@Composable
fun Votes() {
    VotesPageContent()
}
