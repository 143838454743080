package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette

@Composable
fun Spacer() {
    val theme = LocalSitePalette.current
    Box(Modifier.fillMaxWidth().height(theme.dimens.M), contentAlignment = Alignment.Center) {
        Box(Modifier.fillMaxWidth().height(2.px).backgroundColor(theme.colors.colorHint))
    }
}
