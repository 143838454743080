package ru.astrainteractive.kobwebx.core.theme.palette

import com.varabyte.kobweb.compose.ui.graphics.Color

data object DarkSitePalette : SitePalette(
    colors = Colors(
        colorPrimaryVariant = Color.rgb(0x111414),
        colorPrimary = Color.rgb(0x222626),
        colorHint = Color.rgb(0x27292B),
        colorOnPrimary = Color.rgb(0xFFFFFF),
        colorSecondary = Color.rgb(0x1D72F2),
        colorSecondaryVariant = Color.rgb(0xDEBA2D),
        colorOnSecondaryVariant = Color.rgb(0x000000),
        colorOnSecondary = Color.rgb(0xFFFFFF),
    ),
    isDark = true
)
