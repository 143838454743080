package empiresmp.feature.main.ui

import androidx.compose.runtime.Composable
import empiresmp.components.Spacer
import empiresmp.components.layouts.PageLayout
import empiresmp.components.navbar.EmpireSmpDesktopNavBar
import empiresmp.feature.main.presentation.model.MainPagerModel
import empiresmp.feature.main.ui.component.MainPagerModelContent
import empiresmp.feature.main.ui.component.MainTitlePage

@Composable
fun MainPageContent() {
    PageLayout {
        EmpireSmpDesktopNavBar()
        MainTitlePage()
        Spacer()
        MainPagerModelContent(MainPagerModel.Unique)
        Spacer()
        MainPagerModelContent(MainPagerModel.Possibilities)
        Spacer()
        MainPagerModelContent(MainPagerModel.Rules)
        Spacer()
        MainPagerModelContent(MainPagerModel.Towns)
    }
}
