package ru.astrainteractive.kobwebx.core.resource

object Resources {
    val favicon = JSResource.Image("favicon.ico")
    val iconBack = JSResource.Image("icons/icon_back.svg")
    val iconDiscord = JSResource.Image("icons/icon_discord.svg")
    val iconHref = JSResource.Image("icons/icon_href.svg")
    val iconTelegram = JSResource.Image("icons/icon_telegram.svg")
    val iconVk = JSResource.Image("icons/icon_vk.svg")
    val iconYoutube = JSResource.Image("icons/icon_youtube.svg")
    val iconTiktok = JSResource.Image("icons/icon_tiktok.svg")
    val iconGithub = JSResource.Image("icons/icon_github.png")
    val iconArrowDown = JSResource.Image("icons/icon_arrow_down.svg")
    val iconArrowUp = JSResource.Image("icons/icon_arrow_up.svg")
    val ajomatBuy = JSResource.Image("images/ajomat_buy.png")
    val bees = JSResource.Image("images/bees.png")
    val cell = JSResource.Image("images/cell.png")
    val mcdonalds = JSResource.Image("images/mcdonalds.jpg")
    val cinna = JSResource.Image("images/cinna.jpg")
    val divBanner = JSResource.Image("images/div_banner.png")
    val headInjury = JSResource.Image("images/head_injury.png")
    val iconResourcePack = JSResource.Image("icons/icon_resource_pack.jpg")
    val bolt = JSResource.Image("icons/bolt.svg")
    val help = JSResource.Image("icons/help.svg")
    val home = JSResource.Image("icons/home.svg")
}
