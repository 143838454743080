package ru.astrainteractive.kobwebx.core.theme.typography

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.vars.animation.TransitionDurationVars
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.theme.composable.toSitePalette
import ru.astrainteractive.kobwebx.core.theme.composable.toThemeType

private fun typographyStyle(
    name: String,
    fontSize: CSSSizeValue<CSSUnit.px>,
    fontWeight: FontWeight = FontWeight.Bold
) = ComponentStyle(name) {
    base {
        Modifier
            .color(colorMode.toThemeType().toSitePalette().colors.colorOnPrimary)
            .padding(leftRight = 10.px, topBottom = 5.px)
            // This would be a real link in an actual app, but here we just create a fake menu
            .cursor(Cursor.Pointer)
            .fontSize(fontSize)
            .fontWeight(fontWeight)
            .transition(CSSTransition("ease-in-out", duration = TransitionDurationVars.Normal.value()))
            .textDecorationLine(TextDecorationLine.None)
    }
    hover {
        Modifier.color(colorMode.toThemeType().toSitePalette().colors.colorSecondary)
    }
}

val H2 = typographyStyle(
    name = "h2-item",
    fontSize = 22.px
)
val H2Mobile = typographyStyle(
    name = "h2-item_mobile",
    fontSize = 18.px
)
val H3 = typographyStyle(
    name = "h3-item",
    fontSize = 20.px
)
val H3Mobile = typographyStyle(
    name = "h3-item_mobile",
    fontSize = 16.px
)
val H5 = typographyStyle(
    name = "h6-item",
    fontSize = 16.px
)
val H5Mobile = typographyStyle(
    name = "h6-item_mobile",
    fontSize = 13.px
)
val Body2 = typographyStyle(
    name = "body2-item",
    fontSize = 12.px
)
val Body2Mobile = typographyStyle(
    name = "body2-item_mobile",
    fontSize = 10.px
)
