package empiresmp

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.App
import ru.astrainteractive.kobwebx.core.theme.composable.ThemeApplication

@App
@Composable
fun Application(content: @Composable () -> Unit) {
    ThemeApplication { content.invoke() }
}
