package ru.astrainteractive.kobwebx.core.resource

object Hrefs {
    const val VK = "https://vk.com/empireprojekt"
    const val DICSORD = "https://discord.gg/Gwukdr8"
    const val YOUTUBE = "https://www.youtube.com/channel/UCiJSULM95XwkFLRilwFxXtg"
    const val TELEGRAM = "https://t.me/empiresmp"
    const val RESOURCE_PACK = "https://empireprojekt.ru/files/EmpireProjektPack.zip"
    const val CLIENT_PACK = "files/EmpireProjektClientPack.zip"
    const val IP = "Play.EmpireProjekt.ru"
    const val GITHUB = "https://github.com/Astra-Interactive"
}
