package ru.astrainteractive.kobwebx.core.theme.palette

import com.varabyte.kobweb.compose.ui.graphics.Color

data object LightSitePalette : SitePalette(
    colors = Colors(
        colorPrimaryVariant = Color.rgb(0xF2F6F9),
        colorPrimary = Color.rgb(0xFFFFFF),
        colorHint = Color.rgb(0x757c82),
        colorOnPrimary = Color.rgb(0x404040),
        colorSecondary = Color.rgb(0x1D72F2),
        colorSecondaryVariant = Color.rgb(0xDEBA2D),
        colorOnSecondaryVariant = Color.rgb(0x202020),
        colorOnSecondary = Color.rgb(0xFFFFFF),
    ),
    isDark = false
)
