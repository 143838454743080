package empiresmp.feature.main.ui.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import empiresmp.components.AdaptiveRow
import empiresmp.components.FilledText
import empiresmp.components.PlatformExt.isMobilePlatform
import empiresmp.feature.main.presentation.model.MainPagerModel
import empiresmp.feature.main.presentation.model.MainPagerModelDirection
import org.jetbrains.compose.web.css.percent
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun MainPagerModelContent(
    model: MainPagerModel,
    underTitleContent: @Composable ColumnScope.() -> Unit = {}
) {
    val theme = LocalSitePalette.current
    AdaptiveRow {
        val image: @Composable () -> Unit = {
            Image(
                src = model.resource.path,
                modifier = Modifier
                    .thenIf(isMobilePlatform, Modifier.width(90.percent))
                    .thenIf(!isMobilePlatform, Modifier.width(50.percent))
                    .borderRadius(theme.dimens.S)
            )
        }
        if (model.direction == MainPagerModelDirection.LTR) {
            image.invoke()
        }
        Column(
            modifier = Modifier
                .thenIf(isMobilePlatform, Modifier.width(90.percent))
                .thenIf(!isMobilePlatform, Modifier.width(50.percent))
                .padding(theme.dimens.L),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.borderRadius(theme.dimens.S).background(theme.colors.colorPrimary).padding(
                    theme.dimens.M
                )
            ) {
                FilledText(
                    text = model.coloredTitle,
                    fillColor = model.backgroundColor.invoke(theme),
                    textColor = model.titleColor.invoke(theme),
                    modifier = Modifier.fillMaxWidth().textAlign(TextAlign.Center)
                )

                underTitleContent.invoke(this)

                Box(Modifier.padding(topBottom = theme.dimens.M))

                SpanText(
                    model.title,
                    theme.typography.h3.toModifier()
                        .color(theme.colors.colorOnPrimary)
                )

                model.text.forEach {
                    SpanText(
                        text = it,
                        modifier = theme.typography.h5.toModifier()
                            .color(theme.colors.colorOnPrimary)
                            .fontWeight(FontWeight.Normal)
                    )
                }
            }
        }

        if (model.direction == MainPagerModelDirection.RTL) {
            image.invoke()
        }
    }
}
