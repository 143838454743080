package ru.astrainteractive.kobwebx.core.theme.palette

import com.varabyte.kobweb.silk.components.style.ComponentStyle

class Typography(
    val h2: ComponentStyle,
    val h3: ComponentStyle,
    val h5: ComponentStyle,
    val body2: ComponentStyle
)
