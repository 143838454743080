package ru.astrainteractive.kobwebx.core.theme.palette

import com.varabyte.kobweb.compose.ui.graphics.Color

data class Colors(
    val colorPrimaryVariant: Color,
    val colorPrimary: Color,
    val colorHint: Color,
    val colorOnPrimary: Color,
    val colorSecondary: Color,
    val colorOnSecondary: Color,
    val colorSecondaryVariant: Color,
    val colorOnSecondaryVariant: Color,
)
