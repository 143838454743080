@file:Suppress("MaxLineLength")

package empiresmp.feature.main.presentation.model

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.graphics.Color
import ru.astrainteractive.kobwebx.core.resource.JSResource
import ru.astrainteractive.kobwebx.core.resource.Resources
import ru.astrainteractive.kobwebx.core.theme.palette.SitePalette

@Suppress("LongParameterList")
abstract class MainPagerModel(
    val coloredTitle: String,
    val backgroundColor: @Composable (SitePalette) -> Color,
    val titleColor: @Composable (SitePalette) -> Color,
    val title: String,
    val text: List<String>,
    val direction: MainPagerModelDirection,
    val resource: JSResource
) {
    data object Unique : MainPagerModel(
        coloredTitle = "Уникальный сервер",
        backgroundColor = { theme -> theme.colors.colorSecondary }, // todo
        titleColor = { theme -> theme.colors.colorOnSecondary }, // todo
        title = "Что такое Empire SMP?",
        text = listOf(
            "Это выживач. Это новые предметы. Это новые механики. Это куча всего нового-другого",
            "Стройте города, знакомьтесь, делайте все что душе угодно"
        ),
        direction = MainPagerModelDirection.LTR,
        resource = Resources.bees
    )

    data object Possibilities : MainPagerModel(
        coloredTitle = "Дружное Комьюнити",
        backgroundColor = { theme -> theme.colors.colorSecondaryVariant }, // todo
        titleColor = { theme -> theme.colors.colorOnSecondaryVariant }, // todo
        title = "Что у вас происходит?",
        text = listOf(
            "Вы можете зайти в наш Discord - пообщаться и расспросить других игроков. В канале #майн-чат отображается игровой чат.",
            "В Telegram канале, а также VK вы можете посмотреть видосики и остальную отвлеченную информацию"
        ),
        direction = MainPagerModelDirection.RTL,
        resource = Resources.ajomatBuy
    )

    data object Rules : MainPagerModel(
        coloredTitle = "Правила",
        backgroundColor = { theme -> theme.colors.colorSecondaryVariant }, // todo
        titleColor = { theme -> theme.colors.colorOnSecondaryVariant }, // todo
        title = "Держите себя в руках",
        text = listOf(
            "На сервере разрешено многое, однако старайтесь не перегибать палку",
            "Не стоит, например, оскорблять игроков, а также их родственников без какой-либо причины",
            "Вас не забанят просто так. Правила описаны кратко, так что их прочтение не займёт много времени, а поймет их даже ребенок"
        ),
        direction = MainPagerModelDirection.LTR,
        resource = Resources.cell
    )

    data object Towns : MainPagerModel(
        coloredTitle = "Города",
        backgroundColor = { theme -> theme.colors.colorSecondary }, // todo
        titleColor = { theme -> theme.colors.colorOnSecondary }, // todo
        title = "Создайте свою империю",
        text = listOf(
            "Мы окончательно решили перейти на Towny!",
            "Поэтому теперь у вас есть возможность создавать собственную огромную империю",
            "Для мини-туториала перейдите на Вики"
        ),
        direction = MainPagerModelDirection.RTL,
        resource = Resources.mcdonalds
    )

    data object Monitoring : MainPagerModel(
        coloredTitle = "Голоса с мониторинга",
        backgroundColor = { theme -> theme.colors.colorSecondary }, // todo
        titleColor = { theme -> theme.colors.colorOnSecondary }, // todo
        title = "Помогите серверу собственноручно!",
        text = listOf(
            "На этой странице вы можете помочь серверу привлечь новых игроков проголосовав за него на других мониторингах",
            "Эта страница сделана таким образом, чтобы голосовать можно было максимально быстро и легко",
            "Просто нажмите на один вариант, а лучше на все. И в появившемся окне проголосуйте за сервер",
            "Желтым обозначаются сайты на которые вы уже кликали!"
        ),
        direction = MainPagerModelDirection.RTL,
        resource = Resources.cinna
    )
}
