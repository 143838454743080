package ru.astrainteractive.kobwebx.core.theme.composable

import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.vh
import ru.astrainteractive.kobwebx.core.theme.presentation.ThemeKrate

@Composable
fun ThemeApplication(content: @Composable BoxScope.() -> Unit) {
    val appTheme by ThemeKrate.stateFlow.collectAsState()
    CompositionLocalProvider(LocalSitePalette provides appTheme.toSitePalette()) {
        SilkApp {
            ColorMode.currentState.value = appTheme.toColorMode()
            Surface(
                modifier = SmoothColorStyle
                    .toModifier()
                    .minHeight(100.vh)
                    .scrollBehavior(ScrollBehavior.Smooth),
                content = content
            )
        }
    }
}
