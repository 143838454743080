package empiresmp.components.hrefcard

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import empiresmp.components.PlatformExt.isMobilePlatform
import org.jetbrains.compose.web.css.px
import ru.astrainteractive.kobwebx.core.resource.Resources
import ru.astrainteractive.kobwebx.core.theme.composable.LocalSitePalette
import ru.astrainteractive.kobwebx.core.theme.util.ThemeExt.typography

@Composable
fun HrefCard(icon: String? = null, text: String, href: String, onClick: (SyntheticMouseEvent) -> Unit = {}) {
    val ctx = rememberPageContext()
    val theme = LocalSitePalette.current
    Box(modifier = Modifier.thenIf(!isMobilePlatform, Modifier.padding(theme.dimens.S))) {
        Row(
            HrefCardStyle
                .toModifier()
                .fillMaxWidth()
                .onClick {
                    onClick(it)
                    ctx.router.navigateTo(href)
                },
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Row(Modifier.fillMaxHeight(), verticalAlignment = Alignment.CenterVertically) {
                icon?.let { icon -> Image(icon, modifier = Modifier.size(54.px)) }
                SpanText(text, theme.typography.h2.toModifier().color(theme.colors.colorOnPrimary))
            }
            Box(Modifier.width(42.px))
            Image(Resources.iconHref.path, modifier = Modifier.size(28.px))
        }
    }
}
