package empiresmp.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint

object PlatformExt {
    val isMobilePlatform: Boolean
        @Composable
        get() {
            val bp = rememberBreakpoint()
            return (bp <= Breakpoint.MD)
        }
}
