import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { empiresmp.pages.Index() }
        ctx.router.register("/votes") { empiresmp.pages.Votes() }
        ctx.router.register("/wiki") { empiresmp.pages.WikiPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(empiresmp.components.LoaderStyle)
        ctx.theme.registerComponentStyle(empiresmp.components.hrefcard.HrefCardStyle)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H2)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H2Mobile)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H3)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H3Mobile)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H5)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.H5Mobile)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.Body2)
        ctx.theme.registerComponentStyle(ru.astrainteractive.kobwebx.core.theme.typography.Body2Mobile)
        ctx.stylesheet.registerKeyframes(empiresmp.components.Spin)
        ru.astrainteractive.kobwebx.core.theme.composable.initColorMode(ctx)
        ru.astrainteractive.kobwebx.core.theme.composable.initTheme(ctx)
        ru.astrainteractive.kobwebx.core.theme.composable.initSiteStyles(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to "Empire Projekt")) {
            empiresmp.Application {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}
